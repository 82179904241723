<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <HouseHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p style="text-align: center;">房产简介</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23546" src="@/assets/house/1/1.jpg" width="1080" height="607" alt=""  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p style="text-align: center;">位置：雅典南部<br>
          户型：3居室<br>
          面积：120㎡</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23547" src="@/assets/house/1/2.jpg" width="1080" height="556" alt=""  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>公寓位于雅典南部，距离雅典国际机场16km，宪法广场11.5km，比雷埃夫斯港13km；周边基础设施齐全，距离超市、银行、学校步行即可达到，距离医院仅2.3km，生活便利，是您居住、投资的高性价比之选。</p>
        <p style="text-align: center;"><img loading="lazy" class="alignnone size-full wp-image-23548" src="@/assets/house/1/3.jpg" width="672" height="248" alt="">客厅</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23549" src="@/assets/house/1/4.jpg" width="900" height="505" alt=""  sizes="(max-width: 900px) 100vw, 900px"></p>
        <p style="text-align: center;">厨房</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23550" src="@/assets/house/1/5.jpg" width="700" height="229" alt=""></p>
        <p style="text-align: center;">卧室<br>
          <img loading="lazy" class="alignnone size-full wp-image-23551" src="@/assets/house/1/6.jpg" width="1080" height="605" alt=""  sizes="(max-width: 1080px) 100vw, 1080px"><br>
          浴室</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23552" src="@/assets/house/1/7.jpg" width="900" height="505" alt=""  sizes="(max-width: 900px) 100vw, 900px"><br>
          <img loading="lazy" class="alignnone size-full wp-image-23553" src="@/assets/house/1/8.jpg" width="1004" height="616" alt=""  sizes="(max-width: 1004px) 100vw, 1004px"><br>
          <img loading="lazy" class="alignnone size-full wp-image-23554" src="@/assets/house/1/9.jpg" width="1080" height="514" alt=""  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p style="text-align: center;">阳台</p>
        <p>&nbsp;</p>
        <p><strong>跃迁今后将继续为大家介绍包租的BnB项目房产。</strong>客户购买的公寓，之后将由专业平台打理，进行短租，客户在投资的同时可以获得预期4%~5%的年回报收益*。<strong>雅典短租服务以低于欧洲市场的优惠价格取胜，房东预期收益相比长租公寓，也更为丰厚。</strong></p>
        <p style="text-align: center;">*预期收益不等于实际收益，投资有风险，选择需谨慎</p>
        <div class="pgc-img" style="text-align: center;"><img loading="lazy" class="alignnone size-full wp-image-23555" src="@/assets/house/1/10.jpg" width="640" height="452" alt="希腊退出经济援助计划，喜提海景房，现在正当时！"></div>
        <div class="pgc-img"><img loading="lazy" class="alignnone size-full wp-image-23556" src="@/assets/house/1/11.jpg" width="640" height="738" alt="希腊退出经济援助计划，喜提海景房，现在正当时！"></div>
        <p>您购买的房产</p>
        <p>也将如上图中所示呈现</p>
        <p>让我们来算笔投资账：</p>
        <p>在短租预定网站BnB筛选完雅典之后，一般1室1厅的优质小户型，出租价格大概在<strong>RMB250-300/天。</strong></p>
        <p>假设一个月能租出去15天，那么房东一个月将会有<strong>RMB3500-4500的收入。</strong></p>
        <p>以跃迁推出的精品房源为例，25万欧元就能买到2套房子。</p>
        <p><strong>退出经济援助计划的希腊，即将在国际市场上大展拳脚，如今正是投资希腊的好时机，投资者应把握住机遇，有效地实现资产增值。</strong></p>
        <p>现在购房，不仅绿卡有保障，而且预期包租收益好，坐在家里就能收租，一举多得！跃迁将用实惠的价格，完善的设施，贴心的服务为您开拓雅典市场，带来全新的投资良机！</p>
      </div>

      <HouseFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import house from "@/house";
import HouseHeader from "@/components/House/HouseHeader";
import HouseFooter from "@/components/House/HouseFooter";

export default {
  name: "news1",
  components: {HouseHeader, HouseFooter},
  data() {
    return {
      id:1,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = house.find(item => item.id === id)
    this.next = house.find(item => item.id === (id + 1))
    this.prev = house.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>